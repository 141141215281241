import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

const ResponsiveDialog = ({ dialog, setDialog, ...props }) => {
  const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      // fullScreen={fullScreen}
      open={dialog.isOpen}
      onClose={() => setDialog({ ...dialog, isOpen: false })}
      aria-labelledby="responsive-dialog-title"
      maxWidth="lg" // Restricts the width
      fullWidth // Ensures the dialog adapts to the specified maxWidth
      {...props}
      sx={{
        "& .MuiDialog-paper": {
          padding: theme.spacing(1), // Reduces padding inside the dialog
        },
      }}
    >
      {dialog.title && (
        <DialogTitle
          id="responsive-dialog-title"
          sx={{ fontSize: "0.875rem", padding: theme.spacing(1) }}
        >
          {dialog.title}
        </DialogTitle>
      )}
      {dialog.content && (
        <DialogContent sx={{ padding: theme.spacing(1) }}>
          {typeof dialog.content === "string" ? (
            <DialogContentText sx={{ fontSize: "0.875rem" }}>
              {dialog.content}
            </DialogContentText>
          ) : (
            dialog.content
          )}
        </DialogContent>
      )}
      <DialogActions
        sx={{ padding: theme.spacing(1), justifyContent: "flex-end" }}
      >
        {dialog.actions?.map((action, index) => (
          <Button
            key={index}
            onClick={action.onClick}
            autoFocus={action.autoFocus}
            sx={{ fontSize: "0.875rem", padding: theme.spacing(0.5, 1) }}
          >
            {action.label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default ResponsiveDialog;
