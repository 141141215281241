import React from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Collapse,
  Button,
  CardActions,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 325,
    background: "rgba(0,0,0,0.5)",
    margin: "20px",
  },
  media: {
    height: 220,
  },
  title: {
    fontFamily: "Nunito",
    fontWeight: "bold",
    fontSize: "1rem",
    color: "#ff5722",
    textAlign: "center",
    textTransform: "uppercase",
  },
  desc: {
    fontFamily: "Nunito",
    fontSize: "1.1rem",
    color: "#ddd",
  },
}));

export default function ImageCard({ place, checked }) {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Collapse in={checked} {...(checked ? { timeout: 1000 } : {})}>
      <Card className={classes.root}>
        <CardMedia
          className={classes.media}
          image={place.imageUrl}
          title={place.description}
          // sx={{margin: 1}}
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="h6"
            component="h1"
            className={classes.title}
          >
            {place.title}
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            color="textSecondary"
            component="p"
            className={classes.desc}
          >
            {place.description}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            fullWidth
            variant={place.buttonVariant}
            onClick={() => navigate(place.route)}
          >
            {place.buttonText}
          </Button>
        </CardActions>
      </Card>
    </Collapse>
  );
}
